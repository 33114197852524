<template>
  <div class="dashboard">
    <div class="row gy-3">
      <div class="col-md-12">
          <h6 class="">Hi, {{activeUser.first_name}}</h6>
          <div v-if="result.department.title">
            <h5 class="text-primary">Welcome to the deparment of {{result.department.title || '...'}}</h5>
            <p>{{ result.department.description }}</p>
          </div>
      </div>
      <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
          <user-email-verification />                   
      </div>
      <div class="col-md-4">
          <div class="card text-center">
              <div class="card-body position-relative">
                  <h5 class="text-primary"> <i class="ri ri-file-text-line align-bottom"></i> Total Cases</h5>
                  <p> {{result.cases.total}} total case{{ result.cases.total > 1 ? 's':''}} in this department</p>
                  <router-link class="text-decoration-underline" to="/cases"> View Cases </router-link>
              </div>
          </div>
      </div>
      <div class="col-md-4">
          <div class="card text-center">
              <div class="card-body position-relative">
                  <h5 class="text-primary"> <i class="ri ri-group-line align-bottom"></i> Lawyers</h5>
                  <p>{{ result.department.lawyers_count > 1 ? `${result.department.lawyers_count} Law Officers are` : `${result.department.lawyers_count} Law Officer is`}} registered</p>
                  <router-link class="text-decoration-underline" to="/lawyers"> View list </router-link>
              </div>
          </div>
      </div>
      <div class="col-md-4">
          <div class="card text-center">
              <div class="card-body position-relative">
                  <h5 class="text-primary"> <i class="ri  ri-account-circle-line align-bottom"></i> Your Account</h5>
                  <p>Information & setttings</p>
                  <router-link class="text-decoration-underline" to="/account"> Account settings </router-link>
              </div>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <statistic-apex-count
          title="TODAY'S CASES"
          :count-end="result.cases.today"
          :total="result.cases.total"
          :figure="result.cases.today"
        />
      </div>
      <div class="col-md-6">
        <statistic-apex-count
          title="PENDING CASES"
          :count-end="result.cases.pending"
          :total="result.cases.total"
          :figure="result.cases.pending"
          chart-color="#FFB02f"
        />
      </div>
      <div class="col-md-6">
        <statistic-apex-count
          title="APPEALED CASES"
          :count-end="result.cases.appealed"
          :total="result.cases.total"
          :figure="result.cases.appealed"
          />
      </div>
      <div class="col-md-6">
        <statistic-apex-count
          title="CLOSED CASES"
          :count-end="result.cases.closed"
          :total="result.cases.total"
          :figure="result.cases.closed"
          />
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-12">
        <case-list />
      </div>
    </div>
  </div>
</template>

<script>

import StatisticApexCount from '@/components/widgets/StatisticApexCount.vue';
import UserEmailVerification from "@/components/UserEmailVerification.vue"
import CaseList from './cases/CaseList.vue';

export default {
    name: 'dashboard',
    components:{
      StatisticApexCount,
      UserEmailVerification,
      CaseList
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      result(){
        return this.$store.state.dashboardData
      }
    },
    created(){
      this.$http.get('/analytics')
      .then((response) => {
        if(response.data.success){
          this.$store.commit('UPDATE_DASHBOARD', response.data.data)
        }
      })
    }
}
</script>

